import style from '@root/scss/avansCredit/index.module.scss';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import CoverFormComponent from '~/components/CoverFormComponent';
import { errorTextValidation } from '~/pages/creditPlus/constats/errorTextValidation';
import {
  isDisabledCardField,
  isDisabledExpiryField
} from '~/utils/disableCardFields';
import {
  excludeValidationForSomeCards,
  useValidation
} from '~/utils/validation';
import { AccordionComponent } from './Accordion';
import { FormField } from './FormField';
import { FormInputs, Props, initialValues } from './helper/helper';

export const Form = ({ userData, onSubmit }: Props) => {
  const { cardValidation, isValidate, setIsValidate } = useValidation({
    noValidateCardNumber: isDisabledCardField(
      userData.cardMask,
      userData.editCardMask
    ),
    noValidateExpiry: isDisabledExpiryField(
      userData.expiry,
      userData.editCardMask
    ),
    validationText: errorTextValidation,
    validateEmail: true
  });

  const {
    watch,
    control,
    handleSubmit,
    clearErrors,
    formState: { errors, isSubmitting }
  } = useForm<FormInputs>({
    resolver: cardValidation,
    defaultValues: initialValues(userData)
  });

  useEffect(() => {
    const subscription = watch((value) => {
      if (excludeValidationForSomeCards(value['card_number'])) {
        setIsValidate(false);
      } else if (!isValidate) {
        setIsValidate(true);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, isValidate, setIsValidate]);

  useEffect(() => {
    if (cardValidation === undefined) {
      clearErrors();
    }
  }, [cardValidation, clearErrors]);

  const handleFormSubmit = (data: FormInputs) => {
    onSubmit(data);
  };

  return (
    <>
      <section className={style.section}>
        <AccordionComponent />

        <div className={style.form}>
          <div className={style.formCardText}>Підтвердження карти</div>
          <div className={style.formCardTextDescr}>
            Для підтвердження введіть дані у відповідні поля нижче.
          </div>

          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <CoverFormComponent isLoading={isSubmitting} />

            <div className={style.container}>
              <div className={style.card_block2}>
                <div className={style.card_block2_border}></div>
                <div className={`${style.blockForm} ${style.card_block2_cvv}`}>
                  <span className={style.blockForm_label}>CVV код:</span>
                  <FormField
                    control={control}
                    name={'cvv'}
                    mask={'999'}
                    error={errors['cvv']}
                    maskChar=""
                    type="password"
                    placeholder="XXX"
                  />
                  <span className={style.blockForm_label_placeholder}>
                    Три цифри на зворотній стороні картки
                  </span>
                </div>
              </div>

              <div className={style.card_block}>
                <div className={style.blockForm}>
                  <span className={style.blockForm_label}>Номер картки:</span>
                  <FormField
                    disabled={
                      isDisabledCardField(
                        userData.cardMask,
                        userData.editCardMask
                      ) || isSubmitting
                    }
                    control={control}
                    name={'card_number'}
                    mask={
                      userData.cardMask
                        ? '9999 XXXX XXXX 9999'
                        : '9999 9999 9999 9999'
                    }
                    error={errors['card_number']}
                    placeholder="____ ____ ____ ____"
                  />
                  <div className={style.expiryBlock}>
                    <span className={style.blockForm_label}>Термін дії:</span>
                    <FormField
                      disabled={Boolean(userData.expiry)}
                      control={control}
                      name={'expiry'}
                      mask={'99/99'}
                      error={errors['expiry']}
                      placeholder="00/00"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <UserAgreement /> */}

            <div className={style.btnBlock}>
              <button type="submit" className={style.btn}>
                Перевірити
              </button>
              <div className={style.dataProtocol}>
                Ваші дані надійно захищені 256-бітовим SSL-протоколом
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};
