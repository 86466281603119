import customLogo from '@root/images/custom/custom-logo.png';
import logoSelfie from '@root/images/selfie/logo.svg';
import { ContentData } from './types';
import logoCredit7 from '@root/images/logos/logo_credit7.svg';
import slonLogo from '@root/images/slon/logo.svg';

export const getPaytechContentData = (): ContentData => ({
  header: {
    title: 'PayTech',
    icon: customLogo,
    phone: '095 428 68 03'
  },
  main: {},
  footer: {
    phone: '095 428 68 03',
    email: 'info@paytech.com.ua',
    workStart: 'Пн-Пт: 9:00 - 18:00',
    workEnd: '',
    firmInfo: ['ТОВ ПЕЙТЕК УКРАЇНА', ' Код ЄДРПОУ: 44103264'],
    linkInfo: {
      linkText: 'Юридична інформація',
      linkRoute: 'https://paytech.com.ua/legal-information'
    }
  }
});

export const getSelfieContentData = (): ContentData => ({
  header: {
    title: 'SelfieCredit',
    icon: logoSelfie,
    phone: '(044) 333 43 56'
  },
  main: {
    classesNames: {
      btn: 'btn__selfie'
    }
  },
  footer: {
    phone: '(044) 333 43 56',
    email: 'info@selfiecredit.com.ua',
    workStart: 'Пн-Пт: 9:00 - 20:00',
    workEnd: 'Сб-Нд: 9:00 - 20:00',
    firmInfo:
      'ТОВ «Селфі Кредит», ЄДРПОУ 43979069, 04070, м. Київ, вул. Сковороди Григорія, 1. Свідоцтво про реєстрацію фінансової установи, серія ФК№В0000369 від 21.09.2021.',
    classesNames: {
      footer: 'footer__selfie'
    }
  }
});

export const getCredit7ContentData = (): ContentData => ({
  header: {
    icon: logoCredit7,
    phone: '093 033 07 33'
  },
  main: {
    classesNames: {
      btn: 'btn__credit7'
    }
  },
  footer: {
    phone: '093 033 07 33',
    email: 'info@credit7.ua',
    workStart: 'Пн-Пт: 8:00 - 23:00',
    workEnd: 'Сб-Нд: 8:00 - 22:00',
    firmInfo:
      'ТОВ "ЛІНЕУРА УКРАЇНА" ЄДРПОУ 42753492, свідоцтво про реєстрацію фінансової установи, серія ФК № 1177 від 19.03.2019р, ліцензія НКФП № 529 від 04.04.2019р. © 2021 ® Credit7. Все права защищены.',
    classesNames: {
      footer: 'footer__credit7'
    }
  }
});

export const getSlonContentData = (): ContentData => ({
  header: {
    title: 'SlonCredit',
    icon: slonLogo,
    phone: '0 800 33 03 83'
  },
  main: {
    classesNames: {
      btn: 'btn__slon'
    }
  },
  footer: {
    phone: '0 800 33 03 83',
    email: 'info@sloncredit.com.ua',
    workStart: 'Пн-Нд: 9:00 - 20:00',
    workEnd: '',
    firmInfo:
      'ТОВ "СЛОН КРЕДИТ".' +
      ' Свідоцтво про реєстрацію Фінансової установи серія ФК № В0000088 від 27.11.2020.' +
      ' Відповідно до Розпорядження НКФП № 1891 від 25.10.2018 року Товариству видано ліцензію на надання коштів у позику, в тому числі і на умовах фінансового кредиту (чинна ліцензія).' +
      ' © 2022 ТОВ "СЛОН КРЕДИТ". Все права защищены.',
    classesNames: {
      footer: 'footer__slon'
    }
  }
});
