import CoverFormComponent from '@comp/CoverFormComponent';
import { OnSubmit, TransactionsIdItems } from '@hooks/useOnSubmit';
import { ContentData } from '@root/constants/formData/types';
import iconpay1 from '@root/images/k7icons/logo_ico_1.svg';
import iconpay2 from '@root/images/k7icons/logo_ico_2.svg';
import iconpay3 from '@root/images/k7icons/logo_ico_3.svg';
import iconpay4 from '@root/images/k7icons/logo_ico_4.svg';
import style from '@root/scss/credit7/index.module.scss';
import {
  excludeValidationForSomeCards,
  useValidation
} from '@root/utils/validation';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Oval } from 'react-loader-spinner';
import CommitionWarning from '~/components/CommitionWarning';
import PayButtons from '~/components/PayButtons';
import RawFields from '~/components/RawFields';
import UserAgreement from '~/components/UserAgreement';
import { UserData } from '~/utils/formatUserData';
import Vector from '../../images/creditPlus/Vector.svg';
import { K7Field } from './K7Field';
import K7InputWrapper from './K7InputWrapper';

export type K7FormInputs = {
  card_number: string;
  expiry: string;
  cvv: string;
  save: string;
  email: string;
};

type K7FormProps = {
  onSubmit: OnSubmit;
  children: React.ReactNode | undefined;
  formData: {
    title: string;
  };
  mainContent: ContentData['main'];
  userData: UserData;
  idTransaction: TransactionsIdItems;
};

export const K7Form = (props: K7FormProps) => {
  const {
    onSubmit,
    idTransaction,
    userData: {
      expiry,
      cardMask,
      options,
      apayEnabled,
      gpayEnabled,
      fee,
      fullAmount,
      payAmount
    }
  } = props;

  const { cardValidation, isValidate, setIsValidate } = useValidation({
    noValidateExpiry: Boolean(expiry),
    noValidateCardNumber: Boolean(cardMask),
    validateEmail: true
  });

  const {
    handleSubmit,
    watch,
    clearErrors,
    control,
    formState: { errors, isSubmitting }
  } = useForm<K7FormInputs>({
    resolver: cardValidation,
    defaultValues: {
      save: 'true',
      card_number: cardMask || '',
      expiry: expiry || '',
      cvv: '',
      email: options.receipt_email || ''
    }
  });

  React.useEffect(() => {
    const subscription = watch((value) => {
      if (excludeValidationForSomeCards(value['card_number'])) {
        setIsValidate(false);
      } else if (!isValidate) {
        setIsValidate(true);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, isValidate, setIsValidate]);

  const [typeOperations, setTypeOperations] = useState('Погашення кредиту');

  useEffect(() => {
    if (options.pageTitle === 'pay') setTypeOperations('Внесення платежу');
    if (options.pageTitle === 'extension') setTypeOperations('Пролонгація');

    if (cardValidation === undefined) {
      clearErrors();
    }
  }, [cardValidation, clearErrors]);

  const { t } = useTranslation(['common']);
  const paymentDetails = [
    {
      description: `Оплата за кредитним договором №${options.agreement_id}`,
      amount: props.userData.payAmount
    },
    { description: 'Сума комісії провайдера', amount: props.userData.fee },
    { description: 'Всього до оплати', amount: props.userData.fullAmount }
  ];
  return (
    <section className={style.blockForm}>
      <form className={style.container_540} onSubmit={handleSubmit(onSubmit)}>
        <h1>{typeOperations}</h1>
        {paymentDetails.map((detail, index) => (
          <div key={index} className={style.childrenBlock}>
            <div
              className={
                detail.description === 'Всього до оплати'
                  ? style.price
                  : style.childrenDescr
              }
            >
              {detail.description}
            </div>
            <span className={style.price}>{detail.amount} ₴</span>
          </div>
        ))}

        <svg width="100%" height="1" className={style.dotted}>
          <line
            x1="0"
            y1="0"
            x2="100%"
            y2="0"
            stroke="#E9E9E9"
            strokeWidth="1"
            strokeDasharray="5,5"
          />
        </svg>
        {/* {children ?? children} */}

        <div className={style.blockForm__form}>
          <CoverFormComponent
            isLoading={isSubmitting || idTransaction.status === 'pending'}
            className="cover-form__k7"
          />
          <K7Field
            disabled={Boolean(cardMask)}
            control={control}
            name={'card_number'}
            label={t('common:card_number')}
            mask={cardMask ? '9999 99** **** 9999' : '9999 9999 9999 9999'}
            error={errors['card_number']}
            isIcon={true}
            errorIcon={Vector}
          />
          <div className={style.inout_row}>
            <K7Field
              disabled={Boolean(expiry)}
              control={control}
              name={'expiry'}
              label="Термін дії"
              mask={'99/99'}
              error={errors['expiry']}
              errorIcon={Vector}
            />
            <K7Field
              control={control}
              name={'cvv'}
              label="CVV"
              mask={'999'}
              error={errors['cvv']}
              maskChar=""
              type="password"
              errorIcon={Vector}
            />
          </div>
          <K7InputWrapper
            label="Ваш email для квитанції"
            error={errors['email']}
            control={control}
            name="email"
          >
            <RawFields
              control={control}
              name={'email'}
              inputMode={'email'}
              classNames={{ error: style.error_text }}
            />
          </K7InputWrapper>
        </div>
        <div className={style.payBtn}>
          <PayButtons
            isApple={apayEnabled}
            isGoogle={gpayEnabled}
            payInfo={{
              fee,
              clientName: options.client_name,
              agreementId: options.agreement_id,
              fullAmount,
              payAmount
            }}
            onSubmit={onSubmit}
          />
        </div>

        <div className="button-spin">
          <button
            className={`${style.btn} ${style.btn__purple}`}
            disabled={isSubmitting}
            type="submit"
          >
            Підтвердити
          </button>
          {isSubmitting && (
            <Oval
              wrapperClass="button-spin__spinner"
              strokeWidth={5}
              color="#fff"
            />
          )}
        </div>
        <UserAgreement />
        <CommitionWarning />
        <CommitionWarning text="** Банки можуть стягувати додаткову комісію." />
        <div className={style.blockForm__logotypes}>
          <img src={iconpay1} alt="" />
          <img src={iconpay2} alt="" />
          <img src={iconpay3} alt="" />
          <img src={iconpay4} alt="" />
        </div>
      </form>
    </section>
  );
};
